import React from "react";
import { useQuery } from "@apollo/client";
import GET_CUSTOMER_ORDER from "../../../queries/get-customer-orders";
import { isEmpty } from "lodash";
import { getFormattedDate } from "../../../utils/functions";
import Link from "gatsby-link";

const Orders = ({ authData }) => {
  //console.log(authData.user.id)
  // Get Orders Data.
  let { data } = useQuery(GET_CUSTOMER_ORDER, {
    variables: {
      id: authData.user.databaseId,
    },
  });

  //console.log(data);

  if (isEmpty(data)) {
    //window.location.reload(true)
    //console.log(data);
    return (
      <div className="p-3">
        <h4 className="mb-3">No orders found</h4>
        <Link to="/">
          <button
            className="btn-outline-dark"
            style={{ fontSize: "12px", padding: "8px 12px" }}
          >
            Shop now
          </button>
        </Link>
        <h4 className="mb-3">Manage subscriptions</h4>
        <a
          href="https://billing.stripe.com/p/login/6oE9AGc2meoT2E88ww"
          target="_blank"
          className="btn-outline-dark"
          rel="noopener noreferrer"
          style={{ fontSize: "12px", padding: "8px 12px" }}
        >
          Subscription portal
        </a>
      </div>
    );
  }

  const {
    customer: { orders },
  } = data;

  return (
    <div className="orders-data">
      <div className="p-3">
        <h4 className="mb-3">Manage subscriptions</h4>
        <a
          href="https://billing.stripe.com/p/login/6oE9AGc2meoT2E88ww"
          target="_blank"
          className="btn"
          style={{ fontSize: "12px", padding: "8px 12px" }}
          rel="noopener noreferrer"
        >
          Subscription portal
        </a>
      </div>

      {!isEmpty(orders.edges) ? (
        orders.edges.map((order) => {
          return (
            <div className="" key={order.node.orderKey}>
              <div className="card-header">
                <h4>Order #{order.node.id}</h4>
                <time>Order Placed: {getFormattedDate(order.node.date)}</time>
                <div>Payment Method: {order.node.paymentMethodTitle}</div>
                <div>Order Status: {order.node.status}</div>
                <div>Total: {order.node.total}</div>
              </div>
              <div className="card-body">
                {order.node.lineItems.edges.map((item) => {
                  return (
                    <div className="order-item" key={item.node.product.id}>
                      <h5>{item.node.product.name}</h5>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })
      ) : (
        <div className="p-3">
          <h4 className="mb-3">No orders found</h4>
          <Link to="/">
            <button
              className="btn"
              style={{ fontSize: "12px", padding: "8px 12px" }}
            >
              Shop now
            </button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default Orders;
